import AboutusBlock from '../AboutUsBlock/AboutUsBlock';
import { AboutUsBlockData } from '../AboutUsBlock/AboutUsBlockData';
import ContactInfoBlock from '../ContactInfoBlock/ContactInfoBlock';
import { ContactInfoBlockData } from '../ContactInfoBlock/ContactInfoData';
import ContactUsBlock from '../ContactUsBlock/ContactUsBlock';
import CoppiedToClipboard from '../CoppiedToClipboard/CoppiedToClipboard';
import FaqsBlock from '../FaqsBlock/FaqsBlock';
import { FaqsBlockData } from '../FaqsBlock/FaqsBlockData';
import GeneralInfoBlock from '../GeneralInfoBlock/GeneralInfoBlock';
import PresentationBlockMobile from '../PresentationBlock/mobile/PresentationBlockMobile';
import PresentationBlock from '../PresentationBlock/PresentarionBlock';
import ScrollToHashElement from '../ScrollToHash/ScrollToHashElement';
import ServicesBlock from '../ServicesBlock/ServicesBlock';
import SpecialProductsBlock from '../SpecialProductsBlock/SpecialProductsBlock';
import styles from './MainBlock.module.css';

export const MainBlock = () => {
    return (
        <div className={styles.mainColumn}>
                <CoppiedToClipboard key={0} />
				<ScrollToHashElement />
				<PresentationBlock key={1} />
				<PresentationBlockMobile key={8} />
				<GeneralInfoBlock key={2} />
				<ServicesBlock key={3} />
				<SpecialProductsBlock/>
				<AboutusBlock data={AboutUsBlockData.data} key={4} />
				<FaqsBlock data={FaqsBlockData.data} key={5} />
				<ContactUsBlock key={6} />
				<ContactInfoBlock key={7} data={ContactInfoBlockData.cardsData} />
        </div>
    );
};

export default MainBlock;